import React from "react"
import LiveCategory from "../components/Live/LiveCategory/liveCategory";

const LiveCategoryPage = ({params}) => {
    return (
        <LiveCategory categoryId={params["*"]} />
    );
}

export default LiveCategoryPage
