import React from 'react';
import UiRadio from '../../Ui/UiRadio';
import styles from './style.module.css';

export default function PromethionFilter(props) {
    return (
        <div className={styles.promethionFilter}>
            <div>
                Select package type: 
                <UiRadio
                    name="promethion_type"
                    value="Starter Pack"
                    defaultChecked={props.promethionType==='Starter Pack'}
                    onClick={() => props.setPromethionType('Starter Pack')}
                >
                    Starter Pack
                </UiRadio>
                <UiRadio
                    name="promethion_type"
                    value="CapEx"
                    defaultChecked={props.promethionType==='CapEx'}
                    onClick={() => props.setPromethionType('CapEx')}
                >
                    CapEx
                </UiRadio>
            </div>
            <div>
                The PromethION starter pack enables users wishing to get started with PromethION to do so with minimal commitment. Users will also receive on-site set up training from a member of technical support. Price does not include shipping cost.
            </div>
        </div>
    );
}
