import React from 'react';
import styles from './style.module.css';
import Price from "../../../magento/peregrine/lib/Price";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import ConfiguratorOption, { OptionItem } from './configuratorOption';
import {TogglePrice, isTransactional} from "../../../hooks/siteData";
import UiButton from "../../Ui/UiButton";
import { navigate } from "gatsby";

export default function ProductCard({product, category}) {
    const productName = () => {
        return product.name.replace(category.name, '').trim();
    };

    return(
        <div id={'card-' + product.sku} className={styles.card}>
            <div className={styles.cardTitle}>
                <div className={styles.cardName}>{productName()}</div>
                <div className={styles.cardPrice}>
                    <TogglePrice sku={product.sku}>
                        <Price
                            currencyCode={product.price_range.minimum_price.final_price.currency}
                            value={product.price_range.minimum_price.final_price.value}
                            classes={{decimal: styles.hide, fraction: styles.hide}}
                        />
                    </TogglePrice>
                </div>
            </div>
            <ConfigureButton product={product} />
            <ConfiguratorOptions options={product.items} />
            <ConfigureButton product={product} />
        </div>
    );
}

const ConfiguratorOptions = ({options}) => {
    const sortedOptions = [...options].sort(function (a, b) {
        return a.position - b.position;
    });

    const inclusiveKits = sortedOptions.reduce((acc, curr) => {
        const qty = (curr.type==='radio' && curr.title.startsWith('Kit')) ? 1 : 0;
        return acc + qty;
    }, 0);

    const inclusiveKitsItem = {
        qty: inclusiveKits,
        label: 'Sequencing kits',
        product: {
            sku: ''
        }
    }

    return(
        <div className={styles.options}>
            {sortedOptions.map(option => <ConfiguratorOption option={option} key={option.option_id} />)}
            {inclusiveKits > 0 && <OptionItem item={inclusiveKitsItem} />}
        </div>
    );
}

const ConfigureButton = ({product}) => {
    if (isTransactional()) {
        return(
            <UiButton className="button button--primary button--keyline" onClick={() => navigate(`/${product.url_key}.html`)}>
                <span>Configure package</span>
                <span><FontAwesomeIcon icon={faChevronRight} /></span>
            </UiButton>
        );
    }
    return null;
}
