import React from 'react';
import {gql, useLazyQuery} from "@apollo/client";
import {isBrowser} from "../../../utils/useLocalStorage";
import {Helmet} from "react-helmet";
import {categoryFragments} from "../../../fragments/live/category";
import Category from "../../Category";

export default function LiveCategory({categoryId}) {
    if (! isBrowser()) {
        return null;
    }
    const [makeCategoryQuery, categoryResult] = useLazyQuery(
        categoryQuery,
        {
            variables: {
                categoryId: categoryId
            },
            fetchPolicy: 'network-only',
        }
    );

    if (! categoryResult.called) {
        makeCategoryQuery();
    }

    if (categoryResult.loading) {
        return <div>Loading...</div>;
    }

    if (categoryResult.called && categoryResult.data) {
        const category = categoryResult.data.categoryList[0];
        if (category === "undefined") {
            return null;
        }

        return (
            <>
                <Helmet>
                    <title>{category.name}</title>
                </Helmet>
                <Category
                    category={category}
                />
            </>
        );
    }

    return null;
}

const categoryQuery = gql`
    query categoryList($categoryId: String) {
        categoryList: categoryList(filters: { ids: { eq: $categoryId } }) {
            ...CategoryFields
            products {
                items
                {
                    ...ProductFields
                    ...BundleProductFields
                }
            }
        }
    }

    ${categoryFragments}
`;
