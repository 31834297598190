import React from 'react';
// import { navigate as nav } from "gatsby"
import Configurator from "./Configurator";

const Category = ({category}) => {
    const products = category.products.items;

    if (category.ont_category_type === 'CONFIGURATOR') {
        return (
            <Configurator products={products} category={category}/>
        );
    }

    return null;
    
    // navigate('/404');
}

export default Category;
