import React from 'react';
import styles from './style.module.css';

export default function ConfiguratorOption({option}) {

    switch(option.type) {
        case 'checkbox':
        case 'select':
            return option.required ? <InclusiveOption option={option} /> : null;
        case 'radio':
            return !option.title.startsWith('Kit') ? <ChoiceOption option={option} /> : null;
    }

    console.log("Ignoring option", option);
    return null;
}

export const OptionItem = ({item}) => {
    return(
        <div className={styles.cardItem}>
            <OptionItemContent item={item} />
        </div>
    );
}

const InclusiveOption = ({option}) => {
    const items = [...option.options].sort(function (a, b) {
        return a.position - b.position;
    });

    return items.map(item => <OptionItem item={item} key={item.id} />);
}

const ChoiceOption = ({option}) => {
    return (
        <div className={styles.cardItem}>
            {option.options.map((choice, i) => <ChoiceItem choice={choice} key={choice.id} isFirst={i==0} />)}
        </div>
    );
}

const OptionItemContent = ({item}) => {
    let displayLabel = item.label==='1 Pack' ? 'Flow cells' : item.label;
    displayLabel = item.label.replace('Single ', '');

    return(
        <>
            <div className={styles.cardItemQty}>{item.qty}x</div>
            <div className={styles.cardItemDesc}>{displayLabel}<br/><span className={styles.sku}>{item.product.sku}</span></div>
        </>
    );
}

const ChoiceItem = ({choice, isFirst}) => {
    return(
        <>
            {!isFirst && <div className={styles.cardItemOr}>- or -</div>}
            <OptionItemContent item={choice} />
        </>
    );
}
