import { gql } from "@apollo/client";

export const categoryFragments = gql`
    fragment CategoryFields on CategoryInterface {
        name
        url_path
        url_key
        ont_category_type
        ont_image_url_1
        breadcrumbs {
            category_level
            category_name
            category_url_key
        }
    }

    fragment ProductFields on ProductInterface {
        sku
        name
        short_description {
            html
        }
        url_key
        ont_image_url_1
        price_range {
            minimum_price {
                final_price {
                    currency
                    value
                }
            }
        }
    }

    fragment BundleProductFields on BundleProduct {
        items {
            option_id
            position
            required
            title
            type
            options {
                can_change_quantity
                id
                is_default
                label
                position
                qty
                product {
                    sku
                }
            }
        }
    }
`;
