import React, { useState } from 'react';
import TopActions from '../../TopActions';
import Breadcrumbs from '../../Breadcrumbs';
import UIBackButton from '../../Ui/UiBackButton';
import { TransactionalContent, isChinaStore } from '../../../hooks/siteData';
import ProgressBar from '../../Product/Configurator/ProgressBar';
import ProductHeader from './productHeader';
import PromethionFilter from './promethionFilter';
import ProductCard from './productCard';
import styles from './style.module.css';

export default function Configurator(props) {
    const [promethionType, setPromethionType] = useState('Starter Pack');

    const hasFilter = (props.products.length > 3 && props.category.name==='PromethION');

    return (
        <div>
            <TopActions black="black"/>
            <div className={styles.productTitle}>
                <Breadcrumbs category={props.category} />
                <div className={styles.backButton}>
                    <UIBackButton />
                </div>
                <div className={styles.title}>
                    <div className={styles.productName}>Configure your package</div>
                </div>
            </div>
            <TransactionalContent>
                <ProgressBar steps={['Select package', 'Choose package to view available options']}/>
            </TransactionalContent>
            <ProductHeader category={props.category} />
            {hasFilter && <PromethionFilter category={props.category} promethionType={promethionType} setPromethionType={setPromethionType} />}
            <ProductCards products={props.products} category={props.category} filter={hasFilter ? promethionType : false} />
        </div>
    );
}

function ProductCards({products, filter, category}) {
    const filteredProducts = function() {
        if (!filter) return products;

        const filtered = [];
        products.forEach(product => {
            if (product.name.toLowerCase().includes(filter.toLowerCase())) filtered.push(product);
        });

        return filtered;
    }();

    const contactLink = isChinaStore()
        ? "//nanoporetech.net/contact"
        : `mailto:store@nanoporetech.com?subject=${category.name} enquiry`;
    
    if (!products.length) {
        return <p className={styles.contact}>Please <a onClick={() => window.location.href=contactLink}>contact us</a> for further information about these products.</p>;
    }

    return (
        <div className={styles.cards}>
            {filteredProducts.map(product => <ProductCard product={product} category={category} key={product.sku} />)}
        </div>   
    );
}
