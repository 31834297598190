import React from 'react';
import styles from './style.module.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from '@fortawesome/pro-light-svg-icons';
import devices from './data.json';
import UiMarkdown from '../../Ui/UiMarkdown';
import ContentfulImage from "../../Contentful/ContentfulImage";

export default function ProductHeader({category}) {
    const data = devices[0][category.name.toLowerCase()];

    return(
        <div className={styles.header}>
            <div className={styles.headerName}>{category.name}</div>
            <div className={styles.headerContent}>
                <div className={styles.headerImage}>
                    <ContentfulImage src={category.ont_image_url_1} width={600}/>
                </div>
                <div className={styles.headerDesc}>
                    <UiMarkdown source={data.description} skipHtml={true} />
                </div>
                <div className={styles.headerDocs}>
                    <div>PRODUCT DOCUMENTATION</div>
                    <ul>{data.documentation.map((item,i) => <DocumentationItem item={item} key={i} />)}</ul>
                </div>
            </div>
        </div>
    );
}

const DocumentationItem = ({item}) => {
    return(
        <li>
            <div><FontAwesomeIcon icon={faFilePdf} /></div>
            <div>{item.name}</div>
            <div><a href={item.link}>Download</a></div>
        </li>
    );
}
